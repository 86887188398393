var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wrapper',[_c('section',{staticClass:"promocodes-page"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12 box-tools"},[_c('div',{staticClass:"pull-right"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push('/promocodes/new')}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Add a promocode ")])])])]),_c('vue-good-table',{attrs:{"styleClass":"vgt-table striped","theme":"auris-admin","columns":_vm.columns,"rows":_vm.rows,"totalRows":_vm.totalRecords,"pagination-options":{
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [10, 25, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      },"search-options":{
        enabled: true,
        trigger: 'key-up',
        skipDiacritics: true,
        placeholder: 'Search this table',
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?[_c('button',{staticClass:"btn btn-link user-link",attrs:{"data-id":props.row.id},on:{"click":function($event){return _vm.viewPromocode($event)}}},[_c('i',{staticClass:"fa fa-eye"}),_vm._v(" View")])]:_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }