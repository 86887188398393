<template>
  <section class="books-usage-report-page">
    <div class="row mb-1-25 report__filters">
      <div class="col-md-12 center">
        <div class="row match-height">
          <div class="col-md-2">
            <div class="row">
              <div class="user-filter">
                <div class="label-container">
                  <label>Subscription Status:</label>
                </div>
                <select name="dataType" id="dataType" v-model="dataType">
                  <option value="both">All</option>
                  <option value="home">Home</option>
                  <option value="school">School</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="row">
              <div class="col-md-12">
                <label for="publishers">Publishers</label>
              </div>
              <div class="col-md-12">
                <vue-tags-input id="authors" v-model="publisher" :tags="selectedPublishers"
                                :addOnlyFromAutocomplete="true" placeholder="Choose publishers"
                                :autocomplete-items="filteredPublishers"
                                @tags-changed="newPublishers => selectedPublishers = newPublishers"
                                :autocomplete-min-length="0"></vue-tags-input>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="row">
              <div class="col-md-12">
                <label for="publishers">Authors</label>
              </div>
              <div class="col-md-12">
                <vue-tags-input id="publishers" v-model="author" :tags="selectedAuthors"
                                :addOnlyFromAutocomplete="true" placeholder="Choose authors"
                                :autocomplete-items="filteredAuthors"
                                @tags-changed="newAuthors => selectedAuthors = newAuthors"
                                :autocomplete-min-length="0"></vue-tags-input>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="row">
              <div class="col-md-12">
                <label for="publishers">Collections</label>
              </div>
              <div class="col-md-12">
                <vue-tags-input id="collections" v-model="collection" :tags="selectedCollections"
                                :addOnlyFromAutocomplete="true" placeholder="Choose collections"
                                :autocomplete-items="filteredCollections"
                                @tags-changed="newCollections => selectedCollections = newCollections"
                                :autocomplete-min-length="0"></vue-tags-input>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="row">
              <div class="col-md-12">
                <label>Filter by date</label>
              </div>
              <DatePickerWithDropdown
                reference="time_period"
                @date-range-change="handleChangeDateRange($event, 'getReportData')"
                :value="dateRangeModel"
                :initialDateRange="dateRangeModel"
              />
            </div>
          </div>
          <div class="col-md-2 download-button-locator">
            <button @click="getCSV" class="btn btn-primary"><i v-if="gettingCSV" class="fa fa-spinner fa-spin"></i>Download CSV File</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!onSchoolPage">
      <div class="col-md-2">
        <user-attribute-filters @select="onFilterUserAttributes" :show-user-type="false"></user-attribute-filters>
      </div>
      <div class="col-md-2">
        <reader-attribute-filters @select="onFilterReaderAttributes"></reader-attribute-filters>
      </div>
      <div class="col-md-8 text-right">
        <button @click="modal.show('usageTableExplainer')" class="btn btn-warning btn-sm"><b>Explain columns</b> <i class="fa fa-question-circle ml-2 mr-0"></i></button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 center">
            <vue-good-table
              styleClass="vgt-table striped"
              theme="auris-admin"
              :columns="columns"
              :rows="rows"
              :totalRows="totalRecords"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 25,
                position: 'bottom',
                perPageDropdown: [10, 25, 50, 100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
              }"
              :search-options="{
                enabled: true,
                trigger: 'key-up',
                skipDiacritics: true,
                placeholder: 'Search this table',
              }"
              :isLoading.sync="isLoading"
            >
            <template slot="table-row" slot-scope="props">
              <router-link v-if="props.column.field == 'actions'" :to="`/recordings/${props.row.id}`"><i class="fa fa-eye"></i></router-link>
            </template>
            <template slot="loadingContent">
              <div class="loadingContent">
                  <i class="fa fa-refresh fa-spin"></i>
              </div>
            </template>
            </vue-good-table>
      </div>
    </div>

    <modal title="Usage Table Columns" v-if="modal.isVisible('usageTableExplainer')" @close="modal.hide('usageTableExplainer')" :show-footer="false">
      <ul>
        <li><b>Opens:</b> The number of times a child opened the book and started to read (from start or continueing a past read).</li>
        <li><b>Reads:</b> The number of times the book was read (may or may not have completed).</li>
        <li class="mb-4"><b>Completed:</b> The number of times the book was completed.</li>
        <li><b>Benchmarked Reading Times<sup>*</sup></b>:
          <ul>
            <li><b>Slow:</b> Any session that takes longer than this, is considered a "slow read".</li>
            <li><b>Fast:</b> Any session that completes quicker than this, is considered a "fast read".</li>
            <li><b>Average:</b> Our benchmark of the average time it takes for a child to complete this book.</li>
          </ul>
        </li>
      </ul>
      <p><sup>*</sup> The benchmark times shown are always the latest data, they are not affected by the date range selection. These are updated regularly, using our own reading data. Books need at least 5 children to have read them, before we will start benchmarking.</p>
    </modal>

  </section>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import _ from 'lodash';
import StatisticsMixin from '@/mixins/StatisticsMixin';
import DatePickerWithDropdown from '@/components/DatePickerWithDropdown';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import moment from 'moment';
import UserAttributeFilters from '@/components/UserAttributeFilters';
import ReaderAttributeFilters from '@/components/ReaderAttributeFilters';
import { isCancel } from 'axios';
import DateRangeMixin from '@/mixins/DateRangeMixin';
import SchoolMixin from '@/mixins/SchoolMixin';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/classes/Modal';

export default {
  name: 'books-usage',
  mixins: [StatisticsMixin, DateRangeMixin, SchoolMixin],
  components: {
    VueTagsInput,
    DatePickerWithDropdown,
    VueGoodTable,
    UserAttributeFilters,
    ReaderAttributeFilters,
    ModalJS, // eslint-disable-line
    modal: ModalJS,
  },
  data() {
    return {
      dataType: 'both',
      rows: [],
      books: [],
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Author',
          field: 'author',
          sortable: true,
        },
        {
          label: 'Collections',
          field: 'collection',
          sortable: false,
        },
        {
          label: 'Opens',
          field: 'opens.views',
          type: 'number',
        },
        {
          label: 'Reads',
          field: 'opens.reading_sessions',
          type: 'number',
        },
        {
          label: 'Completed',
          field: 'opens.complete_reads',
          type: 'number',
        },
        {
          label: 'Slow',
          field: 'benchmarked_reading_times.slow',
          type: 'number',
          formatFn: this.formatSeconds,
        },
        {
          label: 'Average',
          field: 'benchmarked_reading_times.avg',
          type: 'number',
          formatFn: this.formatSeconds,
        },
        {
          label: 'Fast',
          field: 'benchmarked_reading_times.fast',
          type: 'number',
          formatFn: this.formatSeconds,
        },
      ],
      filteredBooks: [],
      tags: [],
      totalRecords: 0,
      publisher: '',
      selectedPublishers: [],
      range: 10,
      tab: 'today',
      delay: 10,
      lastPage: 0,
      showReadingSessions: false,
      isLoading: false,
      author: '',
      selectedAuthors: [],
      collectionTags: [],
      collection: '',
      selectedCollections: [],
      rawPublishers: [],
      rawAuthors: [],
      rawCollections: [],
      gettingCSV: false,
      challenge: 'all',
      readerType: 'all',
      onSchoolPage: false,
      modal: new Modal({
        usageTableExplainer: false,
      }),
    };
  },
  beforeMount() {
    if (this.$route && this.$route.params.schoolId) {
      this.loadPageHeadings('Book Usage Report');
      this.onSchoolPage = true;

      // VGT doesn't support dynamically hiding columns: https://github.com/xaksis/vue-good-table/issues/956
      this.columns = this.columns.map((c) => {
        c.hidden = c.field.includes('benchmarked_reading_times');
        return c;
      });
    }
    this.getReportData();
    this.getAutocompleteData();
  },
  mounted() {
    if (this.$route && !this.$route.params.schoolId && !this.$route.params.classId) {
      this.$store.commit('SET_CURRENT_USER', null);
      this.$store.commit('SET_CURRENT_SCHOOL', null);
    }
  },
  methods: {
    onFilterUserAttributes(e) {
      this.challenge = e.challenge;
      this.userType = 'all';
      this.getReportData();
    },
    onFilterReaderAttributes(e) {
      this.readerType = e.readerType;
      this.getReportData();
    },
    getCSV() {
      this.gettingCSV = true;
      const publishersArray = this.selectedPublishers.map((p) => p.id);
      const authorsArray = this.selectedAuthors.map((a) => a.text);
      const collectionsArray = this.selectedCollections.map((c) => c.id);
      const payload = {
        startDate: this.dateRange.startDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString(),
        delay: this.delay,
        lastPage: this.lastPage,
        showReadingSessions: this.showReadingSessions,
        schoolId: this.$route.params.schoolId ? this.$route.params.schoolId : '',
        classId: this.$route.params.classId ? this.$route.params.classId : '',
        assignedTo: this.onSchoolPage ? 'school' : this.dataType ? this.dataType : '',
        challenge: this.challenge,
        readerType: this.readerType,
        isCsvRequest: true,
        publishersArray: publishersArray.join(','),
        authorsArray: authorsArray.join(','),
        collectionsArray: collectionsArray.join(','),
      };
      this.$store.dispatch('reports/getBooksUsage', payload).then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', `books_usage_report_${moment().format('DD/MM/YYYY')}.csv`);
          link.style = 'visibility:hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }).finally(() => {
        this.gettingCSV = false;
      });
    },
    getAutocompleteData() {
      this.$store.dispatch('admins/getBookResources', 'collections').then((resource) => {
        this.rawCollections = resource;
      }).catch((e) => this.$apiResponse.renderErrorMessage(e));
      this.$store.dispatch('admins/getBookResources', 'authors').then((resource) => {
        this.rawAuthors = resource;
      }).catch((e) => this.$apiResponse.renderErrorMessage(e));
      this.$store.dispatch('admins/getBookResources', 'publishers').then((resource) => {
        this.rawPublishers = resource;
      }).catch((e) => this.$apiResponse.renderErrorMessage(e));
    },
    getReportData() {
      this.$store.dispatch('app/setGlobalLoading', true);
      this.isLoading = true;
      const payload = {
        startDate: this.dateRange.startDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString(),
        delay: this.delay,
        lastPage: this.lastPage,
        showReadingSessions: this.showReadingSessions,
        schoolId: this.$route.params.schoolId ? this.$route.params.schoolId : '',
        classId: this.$route.params.classId ? this.$route.params.classId : '',
        assignedTo: this.onSchoolPage ? 'school' : this.dataType ? this.dataType : '',
        challenge: this.challenge,
        readerType: this.readerType,
      };

      this.$store.dispatch('reports/getBooksUsage', payload).then((books) => {
        this.books = books.map((b) => {
          b.tags = b.tags.join(', ');
          return b;
        });
      }).catch((error) => {
        // catch and ignore cancellation warnings
        if (!isCancel(error)) {
          this.$apiResponse.renderErrorMessage(error);
        }
      }).finally(() => {
        this.$store.dispatch('app/setGlobalLoading', false);
        this.isLoading = false;
      });
    },
    updateDelay() {
      this.delay = this.range;
      this.getReportData();
    },
    updateLastPage() {
      this.getReportData();
    },
    formatBookOpens(opens) {
      return `<ul><li>Views: ${opens.views}</li><li>Unique Reads: ${opens.reading_sessions}</li><li>Complete Reads: ${opens.complete_reads}</li></ul>`;
    },
    filterBooks() {
      let filteredBooks = [];
      if (_.isEmpty(this.selectedPublishers) && _.isEmpty(this.selectedAuthors) && _.isEmpty(this.selectedCollections)) {
        this.rows = this.books;
        return;
      }

      filteredBooks = this.books.filter((b) => {
        const conditionsArray = [];
        // Check publishers
        const matchedPublishers = this.selectedPublishers.filter((p) => b.publisher === p.text.toLowerCase().split(' ').join('-') || p.text === 'All');
        if (!_.isEmpty(this.selectedPublishers)) {
          conditionsArray.push(matchedPublishers.length > 0);
        }
        // Check authors
        const matchedAuthors = this.selectedAuthors.filter((a) => b.author === a.text || a.text === 'Any');
        if (!_.isEmpty(this.selectedAuthors)) {
          conditionsArray.push(matchedAuthors.length > 0);
        }
        // Check collections
        const matchedCollections = this.selectedCollections.filter((c) => b.collection === c.id || c.text === 'All');
        if (!_.isEmpty(this.selectedCollections)) {
          conditionsArray.push(matchedCollections.length > 0);
        }
        return conditionsArray.every((v) => v === true);
      });
      this.rows = _.uniqBy(filteredBooks, 'id');
    },
    formatHyphenatedString(x) {
      const textArray = x.split('-');
      const arrayToJoin = [];
      textArray.forEach((s) => {
        if (s) {
          const string = s ? s.charAt(0).toUpperCase() + s.slice(1) : '';
          arrayToJoin.push(string);
        }
      });
      return arrayToJoin.join(' ');
    },
    formatSeconds(s) {
      if (s < 60) {
        return `${s}s`;
      }
      const m = Math.floor(s / 60);
      const rs = s - (m * 60);
      return `${m}m ${rs}s`;
    },
  },
  computed: {
    filteredTags() {
      return this.tags.filter((t) => new RegExp(this.tag, 'i').test(t.text));
    },
    filteredPublishers() {
      return this.publishers.filter((t) => new RegExp(this.publisher, 'i').test(t.text));
    },
    filteredAuthors() {
      return this.authors.filter((t) => new RegExp(this.author, 'i').test(t.text));
    },
    filteredCollections() {
      return this.collectionTags.filter((t) => new RegExp(this.collection, 'i').test(t.text));
    },
    toggleClass() {
      if (this.showReadingSessions === true) {
        return 'fa-toggle-on';
      }
      return 'fa-toggle-off';
    },
    authors() {
      const rtnValue = this.rawAuthors.map((a) => ({ id: a.slug, text: a.name }));
      return rtnValue;
    },
    publishers() {
      const filteredPublishers = this.rawPublishers.filter((p) => p !== null);
      return filteredPublishers.map((p) => ({ id: p, text: this.formatHyphenatedString(p) }));
    },
  },
  watch: {
    rawCollections() {
      const filteredCollections = this.rawCollections.filter((c) => c);
      const tags = filteredCollections.map((c) => {
        if (c) {
          const textToInsert = this.formatHyphenatedString(c);
          return { id: c, text: textToInsert };
        }
        return { id: 'any', text: 'Any' };
      });

      this.collectionTags = this.rawCollections.length > 0 ? tags : [];
    },
    books() {
      this.filterBooks();
    },
    selectedTags() {
      this.filterBooks();
    },
    selectedPublishers() {
      this.filterBooks();
    },
    selectedAuthors() {
      this.filterBooks();
    },
    selectedCollections() {
      this.filterBooks();
    },
    $route() {
      this.$router.go();
    },
    dataType() {
      this.getReportData();
    },
  },
};
</script>

<style lang="scss">
  .books-usage-report-page .date-range-picker__wrapper .input-group {
    width: 100%;
  }
  .books-usage-report-page .date-range-picker__wrapper .date-range-picker__container {
    width: 100%;
  }

  .user-filter {
    height: 100%;
    select {
      border: 1px solid #ccc;
      display: flex;
      padding: 4px;
      height: 35px;
      width: 100%;
    }
  }

  .download-button-locator {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    max-height: 60px;
    button {
      width: 100%
    }
  }

  // override bootstap
  .row.match-height {
    margin-left: unset;
    margin-right: unset;
  }
</style>
